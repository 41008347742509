<template>
  <button
    @click.prevent="clicked()"
    :disabled="loading"
    class="btn my-3 d-flex align-items-center justify-content-center"
    :class="{
      'w-100': fullWidth,
      'btn-sm': small,
      'btn-outline-secondary': outline,
      'btn-secondary': !outline,
    }"
  >
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"
    ></span>
    <strong>
      <i :class="leftIcon" v-if="leftIcon" />
      {{ label }}
      <i :class="rightIcon" v-if="rightIcon" />
    </strong>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Button Label",
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
  },
  methods: {
    clicked() {
      if (this.disabled) return;
      this.$emit("onClick");
    },
  },
});
</script>
