import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm me-2",
  role: "status",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clicked()), ["prevent"])),
    disabled: _ctx.loading,
    class: _normalizeClass(["btn my-3 d-flex align-items-center justify-content-center", {
      'w-100': _ctx.fullWidth,
      'btn-sm': _ctx.small,
      'btn-outline-secondary': _ctx.outline,
      'btn-secondary': !_ctx.outline,
    }])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("strong", null, [
      (_ctx.leftIcon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(_ctx.leftIcon)
          }, null, 2))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.label) + " ", 1),
      (_ctx.rightIcon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(_ctx.rightIcon)
          }, null, 2))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}