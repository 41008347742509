<template>
  <ConfirmationAlert />
  <SubscriptionModal />
  <AddCreditCardModal />
  <CreditCardsModal />
  <NotificationToast />
  <router-view />
</template>
<script>
import NotificationToast from "@/components/NotificationToast";
import { defineComponent } from "vue";
import SubscriptionModal from "./views/SubscriptionModal/SubscriptionModal.vue";
import AddCreditCardModal from "./views/AddCreditCardModal/AddCreditCardModal.vue";
import CreditCardsModal from "./views/CreditCardsModal/CreditCardsModal.vue";
import { startFirebase } from "./firebaseinit";
export default defineComponent({
  mounted() {
    console.log("Versão: ", process.env.VUE_APP_VERSION_STRING);
    /**
     * NOTE: SPRINT 26
     * ITEM 1201
     * app criado na conta da phurshell, fiz igual o psi pacientes e so funciona em prod
     * os dados do FB estao como autoday... não me pergunte
     */
    startFirebase();
  },
  created() {
    document.title = "Psiapp Pro | Web";
  },
  components: {
    NotificationToast,
    SubscriptionModal,
    AddCreditCardModal,
    CreditCardsModal,
  },
});
</script>
