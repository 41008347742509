
import ILogin from '@/interfaces/ILogin'
import ISession from '@/interfaces/ISession'
import { MUTATIONS, store } from '@/store'
import { replacePhoneNumberToHash, toPattern } from '@/utils/Helper'
import PhoneCodes from '@/utils/PhoneCodes'
import { unformat } from 'v-money3'
import NetworkService, { Endpoint } from './NetworkService'

export default class UserService {
  network
  constructor() {
    this.network = new NetworkService()
  }

  static TOTAL_REGISTER_STEPS = 5
  static GENDER_OPTIONS = [
    { id: 'female', title: 'Feminino' },
    { id: 'male', title: 'Masculino' },
    { id: 'other', title: 'Outro' },
  ]

  list = (params: any) => {
    return this.network.get(Endpoint.users, params)
  }
  create = async (params: any) => {
    params.appointment_price = unformat(params?.appointment_price, {
      prefix: "R$ ",
      thousands: "",
      decimal: ",",
      precision: 2,
      disableNegative: true,
      minimumNumberOfCharacters: 1,
      modelModifiers: {
        number: true,

      }
    })
    params.phone = params?.phone.clear()
    const res = await this.network.postMultipart(Endpoint.users, params)
    if (res.access_token) {
      await this.prepareUser(res.data, res?.access_token)
    }
    return res
  }
  show = (id: string | number) => {
    return this.network.get(`${Endpoint.users}/${id}`)
  }
  delete = () => {
    const id = store.state.session?.id
    return this.network.delete(`${Endpoint.users}/${id}`)
  }
  update = (id: string | number, params: any) => {
    return this.network.put(`${Endpoint.users}/${id}`, params)
  }
  login = async (params: ILogin) => {
    const res = await this.network.post(Endpoint.login, params)
    if (['psychologist'].includes(res.data.type)) {
      if (res.access_token) {
        const user = await this.show(res.data.id)
        await this.prepareUser(user.data, res?.access_token)
      }
      return res
    } else {
      throw ({ status: false, message: 'Credenciais inválidas' })
    }
  }
  prepareUser = async (data: ISession, access_token: string = '') => {
    const session: ISession = {
      id: data.id,
      name: data.name,
      nickname: data.name.split(' ')[0],
      email: data.email,
      created_at: data.created_at,
      type: data.type,
      profile_image: data.profile_image ? process.env.VUE_APP_IMG_BASE_URL + data.profile_image : null,
      approved: data.approved,
      stripe_id: data.stripe_id,
      charges_enabled: data.charges_enabled,
      account_pending_verification: data.account_pending_verification,
      account_verification_errors: data.account_verification_errors,
      country_code: data.country_code,
      phone: data.phone,
      formattedPhone: this.formatPhone(data),
      subscription: data.subscription,
      favorite_card: store.state.session?.favorite_card || null,
      entry_fee: data.entry_fee
    }
    store.commit(MUTATIONS.SAVE_SESSION, session)

    if (access_token) {
      store.commit(MUTATIONS.SAVE_TOKEN, access_token)
    }
  }
  formatPhone(data: ISession) {
    const phoneCode = PhoneCodes.find(
      (elem) => elem.dial_code == data?.country_code
    );

    const pattern = PhoneCodes.find(
      (elem) => elem.dial_code == data?.country_code
    )?.mask;

    let phoneNumber: String = ''
    if (pattern) {
      phoneNumber =
        data?.country_code == "+55"
          ? data?.phone.formatPhone()
          : toPattern(
            data?.phone,
            "phone",
            replacePhoneNumberToHash(pattern)
          );
    }

    return `${phoneCode?.flag} ${phoneCode?.dial_code} ${phoneNumber}`;
  }
  syncSession = async () => {
    const session = store.state.session
    if (session) {
      const res = await this.show(session.id)
      this.prepareUser(res.data)
    }
  }
  logout = () => {
    store.commit(MUTATIONS.CLEAR_SESSION)
  }
  getTerms = () => {
    return this.network.get(`${Endpoint.termsOfUse}`);
  };
  getOccupationAreas = (params = {}) => {
    return this.network.get(Endpoint.occupationAreas, params);
  };
  getBanks = () => {
    return this.network.get(Endpoint.banks);
  };
  getAddressFromCep = async (cep: string) => {
    return fetch(`https://viacep.com.br/ws/${cep}/json`).then((r) => r.json());
  };
  updateStripePending = (params: object) => {
    return this.network.postMultipart(Endpoint.updateStripePending, params);
  };
}
